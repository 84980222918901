@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 650;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
}

@media (max-width: 520px) {
  html, body, #root {
    height: 100%
    }
}